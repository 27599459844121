import Swiper from 'swiper/bundle';

jQuery( document ).ready(function($) {

  // menu
  document.querySelector('.menu-trigger').addEventListener('click', function() {
    document.querySelector('.menu-primary').classList.toggle('active');
    this.classList.toggle('active');
  });

  // hero recent post slider
  const swiper = new Swiper('#recent-post-slider', {
    autoplay: {
      delay: 5000,
    },
    speed: 1000,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  // recent post slider
  const swiper2 = new Swiper('#recent-posts-mini', {
    speed: 1000,
    loop: true,
    slidesPerView: 1,
    spaceBetween: 30,
    watchSlidesProgress: true,
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
    },
    breakpoints: {
      // when window width is >= 992px
      992: {
        slidesPerView: 3,
        spaceBetween: 40
      }
    }
  });

  // Artist videos slider
  const swiper3 = new Swiper('.artist-videos', {
    speed: 1000,
    watchSlidesProgress: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  });

  // load all
  $('.load-more').click(function(){
    $(this).parent().find('.wrapper').toggleClass('show-all')
    if($(this).html() == 'Load Less Posts'){
      $(this).html('Load More Posts')
    } else{
      $(this).html('Load Less Posts')
    }
  });

  $('#more-posts').click(function(e){
    e.preventDefault()
    $(this).parent().toggleClass('show-all')
    if($(this).html() == 'Load More'){
      $(this).html('Load Less')
    } else{
      $(this).html('Load More')
    }
  });

  $('#more-images').click(function(e){
    e.preventDefault()
    $(this).parent().toggleClass('show-all')
    if($(this).html() == 'Load More'){
      $(this).html('Load Less')
    } else{
      $(this).html('Load More')
    }
  });

  // instagram
  $.ajax( {
		url: 'https://www.juicer.io/api/feeds/sonynashville',
		method: 'GET',
		dataType: 'json',
		success: data => {
      console.log(data)
			let html = '';
			let i = 0;
			for ( let item of data.posts.items ) {
				html += '<div>';
				html += '<a href="' + item.full_url + '" target="_blank">';
				html += '<img src="' + item.image + '" alt="' + item.id + '" />';
				html += '</a>';
				html += '</div>';

				i ++;
				if ( i >= 8 ) {
					break;
				}
			}

			$( '#instagram-posts' ).html( html );
		},
		error: () => {
			alert( 'Error fetching Instagram posts!' );
		}
	} );

});